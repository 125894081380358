html {
  font-size: 14px;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;
  padding: 0 15px;
}

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}


@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.eot');
  src: local('Avenir Next Cyr Bold Italic'), local('AvenirNextCyr-BoldItalic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Light.eot');
  src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Light.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot');
  src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.eot');
  src: local('Avenir Next Cyr Thin Italic'), local('AvenirNextCyr-ThinItalic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Italic.eot');
  src: local('Avenir Next Cyr Italic'), local('AvenirNextCyr-Italic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Italic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.eot');
  src: local('Avenir Next Cyr Heavy Italic'), local('AvenirNextCyr-HeavyItalic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.eot');
  src: local('Avenir Next Cyr Medium Italic'), local('AvenirNextCyr-MediumItalic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.eot');
  src: local('Avenir Next Cyr Light Italic'), local('AvenirNextCyr-LightItalic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.eot');
  src: local('Avenir Next Cyr Ultra Light Italic'), local('AvenirNextCyr-UltraLightIt'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot');
  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot');
  src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot');
  src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot');
  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.eot');
  src: local('Avenir Next Cyr Demi Italic'), local('AvenirNextCyr-DemiItalic'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot');
  src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Thin.woff') format('woff'),
  url('../fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Museo-100';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-100.ttf');
  src: local('Museo-100'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-100.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-100italic';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-100italic.ttf');
  src: local('Museo-100italic'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-100italic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-300';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-300.ttf');
  src: local('Museo-300'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-300italic';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-300italic.ttf');
  src: local('Museo-300italic'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-300italic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-500';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-500.ttf');
  src: local('Museo-500'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-500italic';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-500italic.ttf');
  src: local('Museo-500italic'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-500italic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-700';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-700.ttf');
  src: local('Museo-700'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-700italic';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-700italic.ttf');
  src: local('Museo-700italic'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-700italic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-900';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-900.ttf');
  src: local('Museo-900'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-900.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo-900italic';
  src: url('../fonts/MuseoSansCyrl/MuseoSansCyrl-900italic.ttf');
  src: local('Museo-900italic'),
  url('../fonts/MuseoSansCyrl/MuseoSansCyrl-900italic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'font_icon';
  src:  url('../fonts/font_icon/font_icon.eot?yfqwvv');
  src:  url('../fonts/font_icon/font_icon.eot?yfqwvv#iefix') format('embedded-opentype'),
  url('../fonts/font_icon/font_icon.ttf?yfqwvv') format('truetype'),
  url('../fonts/font_icon/font_icon.woff?yfqwvv') format('woff'),
  url('../fonts/font_icon/font_icon.svg?yfqwvv#font_icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'font_icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-telegram:before {
  content: "\e900";
}
.icon-viber:before {
  content: "\e901";
}
.icon-play:before {
  content: "\e902";
}
.icon-tick:before {
  content: "\e903";
}
.icon-right:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-youtube:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-google-plus:before {
  content: "\e908";
}
.icon-facebook-square:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e910";
}
.icon-player:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e912";
}
.icon-compress:before {
  content: "\e913";
}
.icon-expand:before {
  content: "\e914";
}

body {
  background: url(../images/main-bg.jpg) no-repeat top center;
  background-size: cover;
}

.project-modal-wrap {
  background: url(../images/main-project-bg.jpg) no-repeat center;
  background-size: cover;
}